<template>
  <div class="app-container">
    <el-tabs type="border-card" v-model="tabName">
      <el-tab-pane label="字典列表" name="first">
        <!--字典start-->
        <div style="padding: 20px; padding-bottom: 0">
          <!--字典查询区域start-->
          <el-form :model="searchDictGroupForm" ref="searchDictGroupForm" :inline="true" :rules="searchDictGroupFormRules">
            <el-form-item label="字典名称" prop="dictGroupName">
              <el-input v-model="searchDictGroupForm.dictGroupName" placeholder="请输入字典名称" clearable size="small" @keyup.enter.native="handleDictGroupQuery" />
            </el-form-item>
            <el-form-item label="字典编码" prop="dictGroupCode">
              <el-input v-model="searchDictGroupForm.dictGroupCode" placeholder="请输入字典编码" clearable size="small" @keyup.enter.native="handleDictGroupQuery" />
            </el-form-item>
            <el-form-item label="字典类型：" prop="dictGroupType">
              <el-select v-model="searchDictGroupForm.dictGroupType" size="small" @keyup.enter.native="handleDictGroupQuery">
                <el-option :value="item.dictValue" :label="item.dictLabel" v-for="item of dictTypes" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleDictGroupQuery">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetDictGroupQuery">重置</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!--字典查询区域end-->
        <div class="dict-table">
          <!--字典操作按钮start-->
          <div class="dict-table-operate">
            <el-button type="primary" icon="el-icon-plus" size="mini" @click="addDictGroup">新增字典</el-button>
          </div>
          <!--字典操作按钮end-->
          <!--字典表格start-->
          <div class="table-area">
            <el-table v-if="tabName === 'first'" class="dictTable" ref="dict-table" :data="dictGroupCodeList" style="width: 100%">
              <el-table-column property="dictGroupName" label="字典名称" width="120" align="center"> </el-table-column>
              <el-table-column property="dictGroupCode" label="编码" align="center" min-width="120">
                <template v-slot="scope">
                  <span class="dict-group-code" @click="toDictDetail(scope.row)">{{ scope.row.dictGroupCode }}</span>
                </template>
              </el-table-column>
              <el-table-column property="dictGroupType" label="字典类型" align="center" :formatter="dictTypeFormat">
                <!-- <template v-slot="scope">
                  {{ getDictType(scope.row.dictGroupType) }}
                </template> -->
              </el-table-column>
              <el-table-column label="状态" width="120" align="center">
                <template v-slot="scope">
                  <el-switch v-model="scope.row.status" active-value="1" inactive-value="0" @change="shiftDictGroupStatus(scope.row.id, $event)"> </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="备注" min-width="150" prop="remark" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="创建时间" width="200" align="center" prop="createTime"></el-table-column>
              <el-table-column label="操作" align="center" min-width="100">
                <template v-slot="scope">
                  <el-button :disabled="scope.row.status === '1'" type="text" icon="el-icon-delete" size="mini" @click="delDictGroup(scope.row)">删除</el-button>
                  <el-button size="mini" type="text" icon="el-icon-edit" @click="editDictGroupBtn(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex; justify-content: flex-end">
              <el-pagination background @size-change="handleDictGroupSizeChange" @current-change="handleDictGroupCurrentChange" :current-page="searchDictGroupForm.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="searchDictGroupForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
            </div>
          </div>
        </div>
        <!--字典表格end-->
        <!--字典end-->
        <!--新增字典弹窗start-->
        <el-dialog title="新增字典" :visible.sync="addDictGroupDialog" width="600px" class="add-dict-group-form" close-on-click-modal>
          <el-form ref="add-dict-group-form" label-position="right" :model="addDictGroupForm" label-width="100px" :rules="addDictGroupFormRules">
            <el-form-item label="字典名称：" prop="dictGroupName">
              <el-input v-model="addDictGroupForm.dictGroupName"></el-input>
            </el-form-item>
            <el-form-item label="字典类型：" prop="dictGroupType">
              <el-select v-model="addDictGroupForm.dictGroupType">
                <el-option :value="item.dictValue" :label="item.dictLabel" v-for="item of dictTypes" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="字典编码：" prop="dictGroupCode">
              <el-input v-model="addDictGroupForm.dictGroupCode"></el-input>
            </el-form-item>
            <el-form-item label="字典状态：" prop="status">
              <el-switch v-model="addDictGroupForm.status" active-color="#13ce66" inactive-color="#87939a" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model="addDictGroupForm.remark"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="addDictGroupDialog = false" size="small">取 消</el-button>
            <el-button type="primary" @click="addDictGroupSure" size="small">确 定</el-button>
          </div>
        </el-dialog>
        <!--新增字典弹窗end-->
        <!--编辑字典弹窗start-->
        <el-dialog title="编辑字典" :visible.sync="editGroupDialog" width="600px" class="add-dict-group-form" close-on-click-modal>
          <el-form ref="edit-dict-group-form" label-position="right" :model="editDictGroupForm" label-width="100px" :rules="addDictGroupFormRules">
            <el-form-item label="字典名称：" prop="dictGroupName">
              <el-input v-model="editDictGroupForm.dictGroupName"></el-input>
            </el-form-item>
            <el-form-item label="字典类型：" prop="dictGroupType">
              <el-select v-model="editDictGroupForm.dictGroupType">
                <el-option :value="item.dictValue" :label="item.dictLabel" v-for="item of dictTypes" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="字典编码：" prop="dictGroupCode">
              <el-input v-model="editDictGroupForm.dictGroupCode"></el-input>
            </el-form-item>
            <el-form-item label="字典状态：" prop="status">
              <el-switch v-model="editDictGroupForm.status" active-color="#13ce66" inactive-color="#87939a" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model="editDictGroupForm.remark"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="editGroupDialog = false" size="small">取 消</el-button>
            <el-button type="primary" @click="editDictGroupSure" size="small">确 定</el-button>
          </div>
        </el-dialog>
        <!--编辑字典弹窗end-->
      </el-tab-pane>
      <el-tab-pane label="字典数据" name="second">
        <!--字典标签start-->
        <div style="padding: 20px; padding-bottom: 0">
          <!--字典标签查询区域start-->
          <el-form :model="searchDictForm" ref="searchDictForm" :inline="true" :rules="searchDictFormRules">
            <el-form-item label="字典名称" prop="dictGroupCode">
              <el-select v-model="searchDictForm.dictGroupCode" placeholder="请输入字典名称" clearable size="small" @change="handleDictQuery">
                <el-option v-for="item in dicts" :key="item.id" :label="item.dictGroupName" :value="item.dictGroupCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签名称" prop="dictLabel">
              <el-input v-model="searchDictForm.dictLabel" placeholder="请输入标签名称" clearable size="small" @keyup.enter.native="handleDictQuery" />
            </el-form-item>
            <el-form-item label="标签值" prop="dictValue">
              <el-input v-model="searchDictForm.dictValue" placeholder="请输入标签值" clearable size="small" @keyup.enter.native="handleDictQuery" />
            </el-form-item>
            <el-form-item label="标签键" prop="dictKey">
              <el-input v-model="searchDictForm.dictKey" placeholder="请输入标签键" clearable size="small" @keyup.enter.native="handleDictQuery" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="mini" @click="handleDictQuery">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="resetDictQuery">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--字典标签操作按钮start-->
        <div class="dict-table-operate">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="addDict">新增标签</el-button>
        </div>
        <!--字典标签操作按钮end-->
        <!--字典标签查询区域end-->
        <div class="table-area">
          <!--字典标签表格start-->
          <div class="dict-table-list">
            <el-table v-if="tabName === 'second'" class="dictTable" ref="dict-table" :data="dictList" style="width: 100%">
              <el-table-column property="dictLabel" label="名称" width="120" align="center"> </el-table-column>
              <el-table-column property="dictValue" label="值" align="center"> </el-table-column>
              <el-table-column property="dictKey" label="键" align="center"> </el-table-column>
              <el-table-column label="默认" width="120" align="center">
                <template v-slot="scope">
                  {{ scope.row.isDefault === '1' ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="状态" width="120" align="center">
                <template v-slot="scope">
                  <el-switch v-model="scope.row.status" active-value="1" inactive-value="0" @change="shiftDictStatus(scope.row, $event)"> </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="排序" width="100" prop="sort" align="center"></el-table-column>
              <el-table-column label="备注" min-width="150" prop="remark" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column label="创建时间" width="200" align="center" prop="createTime"></el-table-column>
              <el-table-column label="操作" align="center" min-width="150">
                <template v-slot="scope">
                  <el-button :disabled="scope.row.status === '1'" type="text" icon="el-icon-delete" size="mini" @click="delDict(scope.row)">删除</el-button>
                  <el-button size="mini" type="text" icon="el-icon-edit" @click="editDictBtn(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex; justify-content: flex-end">
              <el-pagination background @size-change="handleDictSizeChange" @current-change="handleDictCurrentChange" :current-page="searchDictForm.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="searchDictForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="dictTotal"> </el-pagination>
            </div>
          </div>
          <!--字典标签表格end-->
        </div>
        <!--字典end-->
        <!--新增字典标签弹窗start-->
        <el-dialog title="新增字典标签" :visible.sync="addDictDialog" width="600px" class="add-dict-group-form" close-on-click-modal>
          <el-form ref="add-dict-form" label-position="right" :model="addDictForm" label-width="100px" :rules="addDictFormRules">
            <el-form-item label="所属字典：" prop="dictGroupCode">
              <el-input v-model="addDictForm.dictGroupCode" disabled></el-input>
            </el-form-item>
            <el-form-item label="标签名称：" prop="dictLabel">
              <el-input v-model="addDictForm.dictLabel"></el-input>
            </el-form-item>
            <el-form-item label="标签值：" prop="dictValue">
              <el-input v-model="addDictForm.dictValue"></el-input>
            </el-form-item>
            <el-form-item label="标签键：" prop="dictKey">
              <el-input v-model="addDictForm.dictKey"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input-number v-model="addDictForm.sort" :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="是否默认：" prop="isDefault">
              <el-switch v-model="addDictForm.isDefault" active-color="#13ce66" inactive-color="#87939a" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="标签状态：" prop="status">
              <el-switch v-model="addDictForm.status" active-color="#13ce66" inactive-color="#87939a" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model="addDictForm.remark"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="addDictDialog = false" size="small">取 消</el-button>
            <el-button type="primary" @click="addDictSure" size="small">确 定</el-button>
          </div>
        </el-dialog>
        <!--新增字典标签弹窗end-->
        <!--编辑字典标签弹窗start-->
        <el-dialog title="编辑标签" :visible.sync="editDialog" width="600px" class="add-dict-group-form" close-on-click-modal>
          <el-form ref="edit-dict-form" label-position="right" :model="editDictForm" label-width="100px" :rules="addDictFormRules">
            <el-form-item label="所属字典：" prop="dictGroupCode">
              <el-input v-model="editDictForm.dictGroupCode" disabled></el-input>
            </el-form-item>
            <el-form-item label="标签名称：" prop="dictLabel">
              <el-input v-model="editDictForm.dictLabel"></el-input>
            </el-form-item>
            <el-form-item label="标签值：" prop="dictValue">
              <el-input v-model="editDictForm.dictValue"></el-input>
            </el-form-item>
            <el-form-item label="标签键：" prop="dictKey">
              <el-input v-model="editDictForm.dictKey"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input-number v-model="editDictForm.sort" :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="是否默认：" prop="isDefault">
              <el-switch v-model="editDictForm.isDefault" active-color="#13ce66" inactive-color="#87939a" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="标签状态：" prop="status">
              <el-switch v-model="editDictForm.status" active-color="#13ce66" inactive-color="#87939a" active-value="1" inactive-value="0"> </el-switch>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input type="textarea" v-model="editDictForm.remark"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="editDialog = false" size="small">取 消</el-button>
            <el-button type="primary" @click="editDictSure" size="small">确 定</el-button>
          </div>
        </el-dialog>
        <!--编辑字典标签弹窗end-->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { checkTextName, checkTextDesc, checkTextNumber } from '@/util/validate/text-validator'
import { addDictGroup, delDictGroup, listDictGroup, listAllDictGroup, updateDictGroup } from '@/service/api/dictGroup/dictgroup-api.js'
import { addDict, delDict, listDict, updateDict } from '@/service/api/dict/dict-api.js'
export default {
  name: 'StandardCode',
  data() {
    const checkDictNameForQuery = (rules, value, callback) => {
      checkTextName(false, '', rules, value, callback, 1, 20)
    }
    const checkDictCodeForQuery = (rules, value, callback) => {
      checkTextNumber(false, '字典编码', rules, value, callback, 1, 20)
    }
    const checkDictGroupName = (rules, value, callback) => {
      checkTextName(true, '字典名称', rules, value, callback, 1, 20)
    }
    const checkDictCode = (rules, value, callback) => {
      checkTextNumber(true, '字典编码', rules, value, callback, 1, 20)
    }
    const checkDictRemark = (rules, value, callback) => {
      checkTextDesc(false, '备注', rules, value, callback, 1, 20)
    }
    const checkDictKey = (rules, value, callback) => {
      checkTextName(false, '标签键', rules, value, callback, 1, 20)
    }
    const checkDictLabel = (rules, value, callback) => {
      checkTextName(true, '标签名称', rules, value, callback, 1, 20)
    }
    const checkDictValue = (rules, value, callback) => {
      checkTextName(true, '标签值', rules, value, callback, 1, 20)
    }
    return {
      tabName: 'first',
      // -- 字典--
      // 字典类型
      dictTypes: [],
      // 字典查询表单
      searchDictGroupForm: {
        dictGroupName: '',
        dictGroupCode: '',
        dictGroupType: '',
        pageNum: 1,
        pageSize: 10
      },
      // 字典数量
      total: 0,
      // 字典查询表单校验
      searchDictGroupFormRules: {
        dictGroupName: [{ required: false, validator: checkDictNameForQuery, trigger: 'change' }],
        dictGroupCode: [{ required: false, validator: checkDictCodeForQuery, trigger: 'change' }]
      },
      // 新增字典表单
      addDictGroupForm: {
        dictGroupName: '',
        dictGroupCode: '',
        status: '1',
        remark: ''
      },
      // 新增字典表单校验
      addDictGroupFormRules: {
        dictGroupName: [{ required: true, validator: checkDictGroupName, trigger: 'change' }],
        dictGroupType: [{ required: true, message: '请选择字典类型', trigger: 'change' }],
        dictGroupCode: [{ required: true, validator: checkDictCode, trigger: 'change' }],
        remark: [{ required: false, validator: checkDictRemark, trigger: 'change' }]
      },
      // 字典列表
      dictGroupCodeList: [],
      // 新增弹窗控制
      addDictGroupDialog: false,
      // 编辑字典弹窗控制
      editGroupDialog: false,
      // 编辑字典表单
      editDictGroupForm: {
        dictGroupName: '',
        dictGroupCode: '',
        dictGroupType: '',
        status: '1',
        remark: ''
      },
      // -- 字典数据--
      dicts: [],
      // 字典查询表单
      searchDictForm: {
        dictGroupCode: '',
        dictLabel: '',
        dictKey: '',
        dictValue: '',
        pageNum: 1,
        pageSize: 10
      },
      // 字典数量
      dictTotal: 0,
      // 字典查询表单校验
      searchDictFormRules: {},
      // 新增字典表单
      addDictForm: {
        dictGroupCode: '',
        dictLabel: '',
        dictValue: '',
        dictKey: '',
        sort: '0',
        isDefault: '0',
        status: '1',
        remark: ''
      },
      // 新增字典表单校验
      addDictFormRules: {
        dictGroupCode: [{ required: true, validator: checkDictCode, trigger: 'change' }],
        dictLabel: [{ required: true, validator: checkDictLabel, trigger: 'change' }],
        dictKey: [{ required: false, validator: checkDictKey, trigger: 'change' }],
        dictValue: [{ required: true, validator: checkDictValue, trigger: 'change' }],
        remark: [{ required: false, validator: checkDictRemark, trigger: 'change' }]
      },
      // 字典列表
      dictList: [],
      // 新增弹窗控制
      addDictDialog: false,
      // 编辑字典弹窗控制
      editDialog: false,
      // 编辑字典表单
      editDictForm: {
        dictGroupCode: '',
        dictLabel: '',
        dictValue: '',
        dictKey: '',
        sort: '0',
        isDefault: '0',
        status: '1',
        remark: ''
      }
    }
  },
  mounted() {
    listDict({ dictGroupCode: 'dict_type' }).then(res => {
      if (res.data.status === 200) {
        this.dictTypes = res.data.data
      }
    })
    // 初始化字典列表
    this.getDictGroupCodeList()
    this.getDictGroupCodeListAll()
  },
  methods: {
    // 跳转到字典详情页
    toDictDetail(row) {
      this.tabName = 'second'
      this.addDictForm.dictGroupCode = row.dictGroupCode
      this.editDictForm.dictGroupCode = row.dictGroupCode
      this.searchDictForm.dictGroupCode = row.dictGroupCode
      this.getDictCodeList()
    },
    // ============================字典=============================
    handleDictGroupSizeChange(val) {
      this.searchDictGroupForm.pageSize = val
      this.getDictGroupCodeList()
    },
    handleDictGroupCurrentChange(val) {
      this.searchDictGroupForm.pageNum = val
      this.getDictGroupCodeList()
    },
    // 点击搜索字典
    handleDictGroupQuery() {
      this.$refs.searchDictGroupForm.validate(valid => {
        if (valid) {
          this.getDictGroupCodeList()
        }
      })
    },
    // 重置字典查询
    resetDictGroupQuery() {
      this.$refs.searchDictGroupForm.resetFields()
      this.getDictGroupCodeList()
    },
    // 点击新增按钮
    addDictGroup() {
      const that = this
      this.addDictGroupDialog = true
      this.$nextTick(() => {
        that.$refs['add-dict-group-form'].clearValidate()
      })
    },
    dictTypeFormat(row) {
      let dicTypeName = ''
      if (this.dictTypes && this.dictTypes.some(i => {
        if (i.dictValue === row.dictGroupType) {
          dicTypeName = i.dictLabel
          return true
        }
      })) return dicTypeName
    },
    // 确认新增字典
    addDictGroupSure() {
      this.$refs['add-dict-group-form'].validate(valid => {
        if (valid) {
          addDictGroup(this.addDictGroupForm)
            .then(res => {
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '新增字典成功！')
              } else {
                this.$message.error(res.data.msg)
              }
              this.$refs['add-dict-group-form'].resetFields()
              this.addDictGroupDialog = false
              this.getDictGroupCodeListAll()
              this.getDictGroupCodeList()
            })
            .catch(err => {
              console.log(err)
              this.addDictGroupDialog = false
            })
        }
      })
    },
    // 获取字典列表
    getDictGroupCodeList() {
      listDictGroup(this.searchDictGroupForm)
        .then(res => {
          if (res.data.status === 200) {
            this.dictGroupCodeList = res.data.data
            this.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 获取全部字典列表
    getDictGroupCodeListAll() {
      listAllDictGroup()
        .then(res => {
          if (res.data.status === 200) {
            this.dicts = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 编辑字典弹窗
    editDictGroupBtn(row) {
      Object.assign(this.editDictGroupForm, row)
      this.editGroupDialog = true
    },
    editDictGroupSure() {
      this.$refs['edit-dict-group-form'].validate(valid => {
        if (valid) {
          updateDictGroup(this.editDictGroupForm)
            .then(res => {
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '修改字典成功！')
              } else {
                this.$message.error(res.data.msg)
              }
              this.editGroupDialog = false
              this.getDictGroupCodeList()
            })
            .catch(err => {
              console.log(err)
              this.editGroupDialog = false
            })
        }
      })
    },
    // 修改状态
    shiftDictGroupStatus(id, val) {
      updateDictGroup({ id: id, status: val })
        .then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg || '状态修改成功！')
          } else {
            this.$message.error('状态修改失败！')
          }
          this.editGroupDialog = false
          this.getDictGroupCodeList()
        })
        .catch(err => {
          console.log(err)
          this.editGroupDialog = false
        })
    },
    // 删除字典
    delDictGroup(row) {
      this.$confirm('是否确认删除名称为"' + row.dictGroupName + '"的字典？', '提示')
        .then(() => {
          delDictGroup({ id: row.id })
            .then(res => {
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '删除字典成功！')
              } else {
                this.$message.error(res.data.msg)
              }
              this.getDictGroupCodeList()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // ============================字典数据=============================
    handleDictSizeChange(val) {
      this.searchDictForm.pageSize = val
      this.getDictCodeList()
    },
    handleDictCurrentChange(val) {
      this.searchDictForm.pageNum = val
      this.getDictCodeList()
    },
    // 点击搜索字典数据
    handleDictQuery() {
      this.addDictForm.dictGroupCode = this.searchDictForm.dictGroupCode
      this.$refs.searchDictForm.validate(valid => {
        if (valid) {
          this.getDictCodeList()
        }
      })
    },
    // 重置字典数据查询
    resetDictQuery() {
      const dictGroupCode = this.searchDictForm.dictGroupCode
      this.$refs.searchDictForm.resetFields()
      this.searchDictForm.dictGroupCode = dictGroupCode
      this.getDictCodeList()
    },
    // 获取字典数据列表
    getDictCodeList() {
      listDict(this.searchDictForm)
        .then(res => {
          if (res.data.status === 200) {
            this.dictList = res.data.data
            this.dictTotal = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 点击新增按钮
    addDict() {
      const that = this
      this.addDictDialog = true
      this.$nextTick(() => {
        that.$refs['add-dict-form'].clearValidate()
      })
    },
    // 确认新增字典数据
    addDictSure() {
      this.$refs['add-dict-form'].validate(valid => {
        if (valid) {
          addDict(this.addDictForm)
            .then(res => {
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '新增标签成功！')
                const dictGroupCode = this.addDictForm.dictGroupCode
                this.$refs['add-dict-form'].resetFields()
                this.addDictForm.dictGroupCode = dictGroupCode
              } else {
                this.$message.error('新增标签失败！')
              }
              this.addDictDialog = false
              this.getDictCodeList()
            })
            .catch(err => {
              console.log(err)
              this.addDictDialog = false
            })
        }
      })
    },
    // 修改状态
    shiftDictStatus(row, val) {
      updateDict({ id: row.id, status: val, dictGroupCode: row.dictGroupCode })
        .then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg || '状态修改成功！')
          } else {
            this.$message.error('状态修改失败！')
          }
          this.editGroupDialog = false
          this.getDictCodeList()
        })
        .catch(err => {
          console.log(err)
          this.editGroupDialog = false
        })
    },
    // 编辑字典数据弹窗
    editDictBtn(row) {
      Object.assign(this.editDictForm, row)
      this.editDialog = true
    },
    editDictSure() {
      this.$refs['edit-dict-form'].validate(valid => {
        if (valid) {
          updateDict(this.editDictForm)
            .then(res => {
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '修改标签成功！')
              } else {
                this.$message.error('修改标签失败！')
              }
              this.editDialog = false
              this.getDictCodeList()
            })
            .catch(err => {
              console.log(err)
              this.editDialog = false
            })
        }
      })
    },
    // 删除字典数据
    delDict(row) {
      this.$confirm('是否确认删除名称为"' + row.dictLabel + '"的标签？', '提示')
        .then(() => {
          delDict({ id: row.id })
            .then(res => {
              if (res.data.status === 200) {
                this.$message.success(res.data.msg || '删除标签成功！')
              } else {
                this.$message.error(res.data.msg)
              }
              this.getDictCodeList()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~$assets/css/common/table-area.scss';
@import '~$assets/css/common/search-area.scss';

::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
.app-container {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  .dict-table-operate {
    padding-left: 20px;
  }
  .dict-group-code {
    color: rgba(64, 158, 255);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
